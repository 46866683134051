document.addEventListener("DOMContentLoaded", function () {

	function newsletterSignup() {
		const field = document.querySelector('#acf-field_66e4afb94b5bd')
		field.value = ''
		field.setAttribute('autocomplete', 'off')
		field.tabIndex = -1
	}
	newsletterSignup()


	const grey_carousel = new Swiper(".grey_carousel", {
		slidesPerView: 1,
		spaceBetween: 30,
		freeMode: false,
		grabCursor: true,
		loop: true,
		autoplay: {
			delay: 4500,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	});

	const vertical_carousel = new Swiper(".vertical_carousel", {
		slidesPerView: 1,
		
		spaceBetween: 30,
		freeMode: false,
		grabCursor: true,
		loop: true,
		autoplay: {
			delay: 4500,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		breakpoints: {
			768: {
				direction: "vertical",
			},
		},
	});
	vertical_carousel.on('resize', function() {
		updateSwiperHeight();
	});
	vertical_carousel.on('slideChangeTransitionEnd', function() {
		updateSwiperHeight();
	});

	function updateSwiperHeight() {
		const swiperContainer = document.querySelector('.vertical_carousel');
		if(!swiperContainer) return 
		const slides = document.querySelectorAll('.swiper-slide');
	
		// Get the maximum height of the slides
		let maxHeight = 0;
		slides.forEach(slide => {
			const slideHeight = slide.offsetHeight;
			if (slideHeight > maxHeight) {
				maxHeight = slideHeight;
			}
		});
	
		if(window.innerWidth > 768) {
			// Set the height of the swiper container
			swiperContainer.style.height = `${maxHeight}px`;
		} else {
			swiperContainer.style.header_carousel = 'auto';
		}
	
		// Update Swiper
		vertical_carousel.update();
	}

	updateSwiperHeight();

	const header_carousel = new Swiper(".header_carousel", {
		slidesPerView: 'auto',
		spaceBetween: 10,
		freeMode: false,
		grabCursor: true,
		loop: true,
		centeredSlides: true,
		autoplay: {
			delay: 4500,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		breakpoints: {
			640: {
				spaceBetween: 15,
			},
			768: {
				spaceBetween: 20,
			},
			1024: {
				spaceBetween: 30,
			},
		},
	});

	// Register the slideChange event handler safely
	header_carousel.on('slideChange', function() {
		updateValues();
	});

	function updateValues() {
		// Safely access the active slide
		let activeSlide = header_carousel.slides[header_carousel.activeIndex];
		if (activeSlide) {
			let amountVal = activeSlide.getAttribute('data-amount');
			let targetVal = activeSlide.getAttribute('data-target');
			let dotsVal = parseInt(activeSlide.getAttribute('data-dots'), 10);
		
			// Update the values in the DOM
			document.getElementById('amountVal').innerText = amountVal || 'N/A';
			document.getElementById('targetVal').innerText = targetVal || 'N/A';
			// You can update targetVal and dotsVal similarly if needed
			
			updateDots(dotsVal);
		}
	}

	function updateDots(count) {
		const dotsContainer = document.getElementById('dotsVal')

		let dots = dotsContainer.querySelectorAll('.svg-inline--fa')
		dots.forEach( (dot, index) => {
			if(index < count) {
				dot.classList.add('active')
			} else {
				dot.classList.remove('active')
			}
		})
	}

	// Update values on page load
	updateValues();
});