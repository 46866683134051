class Accordion {
	constructor(accordionWrapper, groupClass, toggleClass, revealClass) {
		this.accordionWrapper = accordionWrapper
		if(!this.accordionWrapper) return

		this.accordions = this.accordionWrapper.querySelectorAll(groupClass)
		this.groupClass = groupClass
		this.toggleClass = toggleClass
		this.revealClass = revealClass
		
		this.activeQuestion = null
		this.init()
	}

	bindAll() {
		["changeActiveAccordionEvent"].forEach( (fn) => (this[fn] = this[fn].bind(this)))
	}

	changeActiveAccordionEvent(e) {
		let classCheck = this.groupClass.replace(".", "")
		let el = e.srcElement
		let parent = el.parentElement
		if(!parent.classList.contains(classCheck)) parent = parent.parentElement
		this.changeActive(parent)
	}

	changeActive(el) {
		if(this.activeQuestion) {
			this.activeQuestion.classList.remove('active')
			let reveal = this.activeQuestion.querySelector(this.revealClass)
			reveal.style.maxHeight = null
		}

		if(this.activeQuestion === el) {
			this.activeQuestion = null
			return
		}
		this.activeQuestion = el
		let reveal = this.activeQuestion.querySelector(this.revealClass)
		reveal.style.maxHeight = reveal.scrollHeight + 'px'
		
		this.activeQuestion.classList.add('active')
	}

	init() {
		this.bindAll()

		this.accordions.forEach( (accordion) => {
			accordion.classList.add('js-active')
			let question = accordion.querySelector(this.toggleClass)
			question.addEventListener('click', this.changeActiveAccordionEvent)
		})
	}
}

const faqs = document.querySelector('.questions')
const questions = new Accordion(faqs, '.question',  '.title', '.answer')


const content_blocks = document.querySelectorAll('.accordion_service')
const arrayOfNodes = Array.from(content_blocks);
arrayOfNodes.forEach(node => {
	new Accordion(node, '.question',  '.title', '.answer');
    
});